import React from "react";
import Image from "next/image";
import css from "./style.module.scss";

import { NavBar } from "../../../components/NavBar/NavBar";
import { Footer } from "../../../components/Footer/Footer";
import classNames from "classnames";
import { Collection } from "../../api/edu-sharing/collections";
import { CollectionCard } from "../../../components/medienecke/CollectionCard/CollectionCard";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import Link from "next/link";
import { Domain } from "../../../types/common";
import CollectionCarousel from "../../../components/medienecke/CollectionCarousel/CollectionCarousel";

const Medienecke = ({
  isDev,
  editorialCollections,
  publicCollections,
}: {
  isDev: boolean;
  editorialCollections: Collection[];
  publicCollections: Collection[];
}) => {
  return (
    <div>
      <NavBar isDev={isDev} domain={Domain.MEDIENECKE} />
      <div className={css["main-layout"]}>
        <div
          style={{
            width: "100%",
            height: 300,
            position: "relative",
          }}
        >
          <Image
            style={{
              marginLeft: "70px",
            }}
            objectFit="contain"
            layout="fill"
            src={require("../../../public/images/logo-medienecke.svg")}
            alt=""
          />
        </div>
        <h1>Medienecke</h1>

        <div className={css["intro-block"]}>
          <h2 className={css["first-h2"]}>WILLKOMMEN IN DER MEDIENECKE!</h2>
          <p>
            Die MEDIENECKE des KITA HUB BAYERN stellt dir kostenfreie und offen
            zugängliche Bildungsmaterialien für die frühpädagogische Praxis zur
            Verfügung.
          </p>
          <p>
            Damit folgen wir den{" "}
            <a
              href="https://www.unesco.de/bildung/open-educational-resources"
              rel="noopener noreferrer"
              target="_blank"
            >
              Empfehlungen der UNESCO
            </a>{" "}
            zu Open Educational Resources (OER).
          </p>

          <h2>BILDUNGSMATERIALIEN FÜR DIE KITA</h2>
          <p>
            Unsere Auswahl an Bildungs- und Arbeitsmaterialien für die
            frühpädagogische Praxis ist vielfältig. Hier findest du neben
            sorgfältig ausgewählten Konzepten, Ideen, Anleitungen, Tipps und
            Praxisanregungen für den pädagogischen Alltag auch vielfältige
            Materialien zum Selbstlernen sowie praktische Tools.
          </p>

          <p>
            Alle Materialien sind{" "}
            <Link href="/qualitaetskriterien">fachlich und qualitativ</Link>{" "}
            geprüft. Klicke auf die Kacheln, um Material zu finden oder suche
            hier:
          </p>

          <div className={classNames(css["call-to-action"])}>
            {/* <div className={classNames(css["placeholder"])}></div> */}

            <img
              className={classNames(css["arrow-highlight-left"], {
                [css.active]: true,
              })}
              src="/images/middle-action-bar/Chat_Icon_Arrow.svg"
              alt="yellow arrow"
            />

            <a
              href="https://repository.medien.kita.bayern/edu-sharing/components/search"
              rel="noopener noreferrer"
              target="_blank"
            >
              <button aria-label="Zum Suchbereich">ZUM SUCHBEREICH</button>
            </a>

            <img
              tabIndex={0}
              className={classNames(css["arrow-highlight-right"], {
                [css.active]: true,
              })}
              src="/images/bottom-section/LE_Arrow.svg"
              alt="yellow arrow"
            />

            {/* <img
              className={classNames(css["pixelchen"], {
                [css.active]: true,
              })}
              src="/images/Recherche.svg"
              alt="pixelchen mit lupe"
            /> */}
          </div>
          <p>
            Die Materialien orientieren sich weitgehend am Bayerischen Bildungs-
            und Erziehungsplan. Sie sind in sechs grundlegende Themenbereiche
            strukturiert.
          </p>

          <div className={css["collection-card-wrapper"]}>
            {editorialCollections
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((col) => (
                <CollectionCard
                  key={col.url}
                  image={col.image}
                  title={col.title}
                  url={col.url}
                />
              ))}
          </div>

          <p>
            Außerdem erstellen wir verschiedene Sammlungen zu weiteren Themen
            der frühpädagogischen Praxis:
          </p>

          <CollectionCarousel collections={publicCollections} />
        </div>

        <div className={css["block-yellow"]}>
          <div>
            <h3>ENTDECKE DIE MEDIENECKE</h3>
            <p>
              Du möchtest genauer wissen, wie die MEDIENECKE aufgebaut ist und
              alle Funktionen kennenlernen? Dann sieh dir das Video an. Hier
              wird dir erklärt, wie du auf der MEDIENECKE Materialien finden,
              nutzen und teilen kannst.
            </p>
          </div>
          <div className={css["video-container"]}>
            <video
              src="/video/erklaervideo-medien.webm"
              controls
              poster="/video/poster-erklaervideo-medienecke.svg"
            ></video>
          </div>
        </div>

        <div className={css["block-green"]}>
          <h3>NOCH MEHR DIGITALE ANGEBOTE FÜR DIE KITA</h3>
          <p>
            Die Bildungsmaterialien der MEDIENECKE sind Teil des KITA HUB BAYERN
            – das kostenfreie und datenschutzkonforme Dienstleistungs- und
            Bildungsangebot für die frühpädagogische Praxis in Bayern. Als
            landeszentrale Anlaufstelle bringt er Akteurinnen und Akteure im
            Kitabereich, ihre Angebote und vor allem die pädagogischen
            Fachkräfte in einem professionellen Netzwerk zusammen.
          </p>
          <p>
            Der KITA HUB BAYERN ist dein Ort für Kreativität, Vernetzung und
            kollaboratives Arbeiten.
          </p>
        </div>

        <div className={css["block-teal"]}>
          <div className={css["video-column"]}>
            <div className={css["video-container"]}>
              <LiteYouTubeEmbed
                id="1WnZD7E8FKY"
                adNetwork={false} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
                params="" // any params you want to pass to the URL, assume we already had '&' and pass your parameters string
                playlist={false} // Use  true when your ID be from a playlist
                playlistCoverId="1WnZD7E8FKY" // The ids for playlists did not bring the cover in a pattern to render so you'll need pick up a video from the playlist (or in fact, whatever id) and use to render the cover. There's a programmatic way to get the cover from YouTube API v3 but the aim of this component is do not make any another call and reduce requests and bandwidth usage as much as possibe
                poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
                title="OER Kompakt - Was sind Open Educational Resources?"
                noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
              />
            </div>
            <span className={css["video-subtitle"]}>
              <a
                href="https://open-educational-resources.de/was-ist-oer-3-2/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Video von Team OERinfo
              </a>{" "}
              für OERinfo – Informationsstelle OER unter{" "}
              <a
                href="https://creativecommons.org/licenses/by-sa/3.0/de/"
                rel="noopener noreferrer"
                target="_blank"
              >
                CC BY-SA 3.0
              </a>
            </span>
          </div>
          <div className={css["description-column"]}>
            <h3>Was bedeutet OER?</h3>
            <p>
              Open Educational Resources (OER) sind freie und offene
              Bildungsinhalte, die angepasst, verändert und weitergegeben werden
              können. OER bieten in Kombination mit den Creative Commons
              Lizenzen (CC 0, CC BY & CC BY SA) die Möglichkeit, selbst
              erstellte Bildungsinhalte für alle frei zur Verfügung zu stellen.
            </p>
            <p>
              OER erleichtern den Zugang zur Bildung für alle, fördern Austausch
              und Kooperation untereinander und ermöglichen die
              Weiterverarbeitung und Anpassung.
            </p>
            <p>
              Wenn du mehr dazu wissen möchtest, sieh dir dieses Video an oder
              informiere dich bei{" "}
              <a
                href="https://open-educational-resources.de/was-ist-oer-3-2/"
                rel="noopener noreferrer"
                target="_blank"
              >
                oer-info.de.
              </a>
            </p>
          </div>
        </div>

        <div className={css["block-license"]}>
          <div>
            Unsere Inhalte stehen unter der Lizenz CC BY 4.0. Für Inhalte von
            Partnern achte bitte auf die Lizenzbedingungen der verlinkten
            Webseiten.
          </div>
        </div>
      </div>

      <div>
        <Footer domain={Domain.MEDIENECKE} />
      </div>
    </div>
  );
};

export default Medienecke;
